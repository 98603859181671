import React, { useState } from "react";
import {Tabs, TabsContent, TabsList, TabsTrigger } from "@relume_io/relume-ui";
import { AnimatePresence, motion } from "framer-motion";
import {Button} from "@material-tailwind/react";

const FeatureCard = ({ tab }) => {
    return (
        <div className="flex h-full flex-col justify-center p-6 md:p-8 lg:p-16">
            <div className="mb-5 md:mb-6">
                <iframe
                    src={tab.content.mapUrl}
                    width="100%"
                    height="400"
                    frameBorder="0"
                    allowFullScreen
                    aria-hidden="false"
                    tabIndex="0"
                    className="rounded-lg shadow-lg"
                ></iframe>
            </div>
            <h2 className="mb-5 text-4xl font-bold leading-[1.2] md:mb-6 md:text-5xl lg:text-6xl">
                {tab.content.heading}
            </h2>
            <p>{tab.content.description}</p>
            <div className="mt-6 flex items-center gap-4 md:mt-8">
                {tab.content.buttons.map((button, index) => (
                    <a
                        key={index}
                        href={tab.content.directionsUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-block"
                    >
                        <Button {...button}>
                            {button.title}
                        </Button>
                    </a>
                ))}
            </div>
        </div>
    );
};

const GoogleMapTabs = (props) => {
    const { tagline, heading, description, defaultValue, tabs } = {
        ...GoogleMapTabsDefaults,
        ...props,
    };

    const [activeTab, setActiveTab] = useState(defaultValue);
    const MotionTabsContent = motion(TabsContent);

    return (
        <section className="px-[5%] py-16 md:py-24 lg:py-28">
            <div className="container">
                <div className="container mb-12 max-w-lg text-center md:mb-18 lg:mb-20">
                    <p className="mb-3 font-semibold md:mb-4">{tagline}</p>
                    <h1 className="mb-5 text-5xl font-bold md:mb-6 md:text-7xl lg:text-8xl">{heading}</h1>
                    <p className="md:text-md">{description}</p>
                </div>
                <Tabs
                    value={activeTab}
                    onValueChange={setActiveTab}
                    orientation="vertical"
                    className="relative grid auto-cols-fr grid-cols-1 border border-border-primary md:grid-cols-[1.5fr_1fr]"
                >
                    <AnimatePresence initial={false}>
                        {tabs.map((tab) => (
                            <MotionTabsContent
                                key={tab.value}
                                value={tab.value}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: activeTab === tab.value ? 1 : 0 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.6, ease: "easeInOut" }}
                            >
                                <FeatureCard tab={tab} />
                            </MotionTabsContent>
                        ))}
                    </AnimatePresence>
                    <TabsList className="relative grid h-full auto-cols-fr grid-cols-1 border-t border-border-primary md:border-l md:border-t-0">
                        {tabs.map((tab, index) => (
                            <TabsTrigger
                                key={index}
                                value={tab.value}
                                className={`items-start justify-start border-0 border-b border-border-primary px-6 py-6 text-xl font-bold last-of-type:border-0 ${
                                    activeTab === tab.value
                                        ? "bg-red-500 text-white"
                                        : "data-[state=active]:bg-background-primary data-[state=active]:text-text-primary"
                                } md:px-8 md:text-2xl`}
                            >
                                {tab.trigger}
                            </TabsTrigger>
                        ))}
                    </TabsList>
                </Tabs>
            </div>
        </section>
    );
};

const GoogleMapTabsDefaults = {
    tagline: "",
    heading: "Where We Skate",
    description:
        "Check out the skating rinks we skate at below!\n",
    defaultValue: "tab-1",
    tabs: [
        {
            value: "tab-1",
            trigger: "Kinsmen Twin Arenas",
            content: {
                mapUrl: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9503.908915811548!2d-113.5139819!3d53.4509933!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53a01efb180d768b%3A0x63cd6491dbb20f1d!2sKinsmen%20Twin%20Arenas!5e0!3m2!1sen!2sca!4v1724820009834!5m2!1sen!2sca",
                heading: "Kinsmen Twin Arenas",
                description:
                    "Check out the Kinsmen Twin Arenas!",
                directionsUrl: "https://www.google.com/maps/dir//Kinsmen+Twin+Arenas+1979+111+St+NW+Edmonton,+AB+T6J+7C6/@53.4509933,-113.5139819,14z/data=!4m5!4m4!1m0!1m2!1m1!1s0x53a01efb180d768b:0x63cd6491dbb20f1d",
                buttons: [
                    { title: "Directions", variant: "secondary" }
                ],
            }
        },
        {
            value: "tab-2",
            trigger: "Tipton Arena",
            content: {
                mapUrl: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9489.203999656293!2d-113.5114562!3d53.516681!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53a0220347dd7275%3A0x1a9dca81a2aff720!2sTipton%20Arena!5e0!3m2!1sen!2sca!4v1724820105664!5m2!1sen!2sca",
                heading: "Tipton Arena",
                description:
                    "Check out the Tipton Arena!",
                directionsUrl: "https://www.google.com/maps/dir//Tipton+Arena+10828+80+Ave+NW+Edmonton,+AB+T6E,+Canada/@53.516681,-113.5114562,14z/data=!4m5!4m4!1m0!1m2!1m1!1s0x53a0220347dd7275:0x1a9dca81a2aff720",
                buttons: [
                    { title: "Directions", variant: "secondary" }
                ],
            },
        },
        {
            value: "tab-3",
            trigger: "Southside Arena",
            content: {
                mapUrl: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9491.124069891934!2d-113.501797!3d53.5081071!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53a02200b8c39223%3A0xff34a47400dd7898!2sGeorge%20S.%20Hughes%20South%20Side%20Arena!5e0!3m2!1sen!2sca!4v1724821176502!5m2!1sen!2sca",
                heading: "Southside Arena",
                description:
                    "Check out the Southside Arena!",
                directionsUrl: "https://www.google.com/maps/dir//George+S.+Hughes+South+Side+Arena+10525+72+Ave+NW+Edmonton,+AB+T6E+0Z6/@53.5081071,-113.501797,14z/data=!4m5!4m4!1m0!1m2!1m1!1s0x53a02200b8c39223:0xff34a47400dd7898",
                buttons: [
                    { title: "Directions", variant: "secondary" }
                ],
            },
        },
        {
            value: "tab-4",
            trigger: "Callingwood Arena",
            content: {
                mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2372.9399609469697!2d-113.6280345!3d53.5052679!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53a0206b6b7460a9%3A0x1991fe38a522e22e!2sCallingwood%20Twin%20Arenas!5e0!3m2!1sen!2sca!4v1737850419569!5m2!1sen!2sca",
                heading: "Callingwood Arena",
                description:
                    "Check out the Callingwood Arena!",
                directionsUrl: "https://www.google.com/maps?rlz=1C5CHFA_enCA916CA916&gs_lcrp=EgZjaHJvbWUqFAgBEC4YChgNGK8BGMcBGIAEGI4FMgYIABBFGDkyFAgBEC4YChgNGK8BGMcBGIAEGI4FMgkIAhAuGA0YgAQyCQgDEAAYDRiABDIKCAQQABgFGA0YHjINCAUQABiGAxiABBiKBTIKCAYQABiABBiiBDIKCAcQABiABBiiBNIBCDQ1ODRqMWo3qAIAsAIA&um=1&ie=UTF-8&fb=1&gl=ca&sa=X&geocode=KalgdGtrIKBTMS7iIqU4_pEZ&daddr=17740+69+Ave+NW,+Edmonton,+AB+T5T+6X3",
                buttons: [
                    { title: "Directions",
                        variant: "secondary" }
                ],
            },
        },
        {
            value: "tab-5",
            trigger: "Confederation Leisure Arena\n",
            content: {
                mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2374.377007706812!2d-113.52280142326023!3d53.47959327232829!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53a01f3fde5eab41%3A0xe77fe04023f4ce03!2sConfederation%20Leisure%20Centre!5e0!3m2!1sen!2sca!4v1737851134929!5m2!1sen!2sca",
                heading: "Confederation Leisure Arena\n",
                description:
                    "Check out the Confederation Leisure Arena\n!",
                directionsUrl: "https://www.google.com/maps?rlz=1C5CHFA_enCA916CA916&gs_lcrp=EgZjaHJvbWUqFQgBEC4YJxivARjHARiABBiKBRiOBTIGCAAQRRg5MhUIARAuGCcYrwEYxwEYgAQYigUYjgUyEwgCEAAYgwEYkQIYsQMYgAQYigUyCggDEAAYsQMYgAQyEAgEEC4YrwEYxwEYgAQYjgUyDQgFEC4YrwEYxwEYgAQyGQgGEC4YrwEYxwEYiwMYpgMYqAMYsQMYgAQyFggHEC4Y1AIYiwMYnQMYqAMYsQMYgAQyBwgIEAAYjwIyBwgJEAAYjwLSAQgzMDkxajFqN6gCALACAA&um=1&ie=UTF-8&fb=1&gl=ca&sa=X&geocode=KUGrXt4_H6BTMQPO9CNA4H_n&daddr=11204+43+Ave+NW,+Edmonton,+AB+T6J+0X8",
                buttons: [
                    { title: "Directions",
                        variant: "secondary" }
                ],
            },
        },
        {
            value: "tab-6",
            trigger: "Meadows Arena\n",
            content: {
                mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4751.207173370821!2d-113.37376702263546!3d53.45767217232178!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53a01997245a0d93%3A0xf216d7f1958af18f!2sThe%20Meadows%20Community%20Recreation%20Centre!5e0!3m2!1sen!2sca!4v1737852585656!5m2!1sen!2sca",
                heading: "Meadows Arena\n",
                description:
                    "Check out the Meadows Arena\n!",
                directionsUrl: "https://www.google.com/maps/dir/53.5148453,-113.4987815/The+Meadows+Community+Recreation+Centre,+2704+17+St+NW,+Edmonton,+AB+T6T+0X1/@53.4869033,-113.5181707,12z/data=!3m1!4b1!4m17!1m7!3m6!1s0x53a01997245a0d93:0xf216d7f1958af18f!2sThe+Meadows+Community+Recreation+Centre!8m2!3d53.4576722!4d-113.3711921!16s%2Fg%2F11b6gnvfxb!4m8!1m1!4e1!1m5!1m1!1s0x53a01997245a0d93:0xf216d7f1958af18f!2m2!1d-113.3711921!2d53.4576722?entry=ttu&g_ep=EgoyMDI1MDEyMi4wIKXMDSoASAFQAw%3D%3D",
                buttons: [
                    { title: "Directions",
                        variant: "secondary" }
                ],
            },
        }
    ],
};

export default GoogleMapTabs;
