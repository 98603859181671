import React, { useEffect, useState } from "react";

const APIEndPointTestPage = () => {
    const [admins, setAdmins] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAdmins = async () => {
            try {
                const response = await fetch("/api/admins/GetAdmins");

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                setAdmins(data);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchAdmins(); // Run the API call
    }, []);


    if (error) return <div>Error: {error}</div>;
    if (!admins.length) return <div>Loading...</div>;
    

    return (
        <div>
            <h1>Admin List</h1>
            <ul>
                {admins.map((admin) => (
                    <li key={admin.adminId}>{admin.username} , {admin.adminTypeId}</li> // Adjust based on admin entity structure
                ))}
            </ul>
        </div>
    );
};

export default APIEndPointTestPage;
